import PusherManager from './pusher'
import Intercom from './intercom'
import * as Sentry from '@sentry/browser'
import { loadUser, loadAccount, loadMember, loadPartner } from './api'
import { EventManager } from '@papertrail/web3-utils'
import { DateTimeConfig, User, Account, Member, Partner } from '@papertrail/types'
import { isAnAccountId } from './utils'

class Session {
  private user: User
  private account: Account
  private member: Member
  private pusherManager: PusherManager
  // TODO make this private and amend legacy app not to need direct access
  public intercom: Intercom
  public partner: Partner = {
    id: null,
    partnerName: 'Papertrail',
    partnerDomain: null,
    partnerSummary: null,
    images: {
      appLogo: null,
      publicCompany: null,
      publicLogo: null
    }
  }

  boot(pusherApiKey: string, intercomAppId: string) {
    this.pusherManager = new PusherManager(pusherApiKey, true)
    this.pusherManager.connect()

    this.intercom = new Intercom()
    this.intercom.boot(intercomAppId)

    //listen for pusher events (another user updated the member)
    EventManager.subscribe(['member_was_updated'], () => {
      this.reloadMember()
    })
    //TODO - if there was an 'account_was_updated' pusher event we could listen for that too
  }

  async getPartner(): Promise<Partner> {
    try {
      return (this.partner = await loadPartner())
    } catch {
      // Return default
      return this.partner
    }
  }

  reset() {
    this.user = undefined
    this.account = undefined
    this.member = undefined
    this.intercom.logout()
  }

  getUser() {
    return this.user
  }

  getAccount() {
    return this.account
  }

  getMember() {
    return this.member
  }

  getDateTimeConfig(): DateTimeConfig {
    if (this.user) {
      return this.user.datetimeConfig
    } else {
      return { timeZone: 'Europe/London', timeFormat: 'H:mm a', dateFormat: 'Do MMM YYYY', relative: false }
    }
  }

  async loadUser() {
    try {
      const user = await loadUser()
      this.setUser(user)
    } catch (e) {
      this.reset()
      if (e.httpStatus !== 401) {
        Sentry.captureException(e)
      }
    }
  }

  // Call when switching accounts
  setAccountId(newAccountId: string) {
    if (this.account == undefined || this.account.id !== newAccountId) {
      if (newAccountId === undefined || !isAnAccountId(newAccountId)) {
        this.unsetAccountAndMember()
      } else {
        this.loadAccount(newAccountId)
        this.loadMember(newAccountId)
      }
    }
  }

  // Call if your MFE modifies the current account
  reloadAccount() {
    if (this.account) {
      this.loadAccount(this.account.id)
    }
  }

  // Call if your MFE modifies the current member
  reloadMember() {
    if (this.account) {
      this.loadMember(this.account.id)
    }
  }

  private setUser(user) {
    this.user = user
    this.pusherManager.userChannel('subscribe', user.id)
    this.intercom.setUser(user)
    //broadcast that the user model has changed. MFEs which need to know can subscribe to this event.
    EventManager.broadcast('papertrail:user_updated', user)
  }

  private setAccount(account) {
    this.account = account
    this.pusherManager.accountChannel('subscribe', account.id)
    //broadcast that the account model has changed. MFEs which need to know can subscribe to this event.
    EventManager.broadcast('papertrail:account_updated', account)
  }

  private setMember(member) {
    this.member = member
    //broadcast that the member model has changed. MFEs which need to know can subscribe to this event.
    EventManager.broadcast('papertrail:member_updated', member)
  }

  private unsetAccountAndMember() {
    if (this.account) {
      this.pusherManager.accountChannel('unsubscribe', this.account.id)
      this.account = undefined
      this.member = undefined
      EventManager.broadcast('papertrail:account_updated', undefined)
      EventManager.broadcast('papertrail:member_updated', undefined)
    }
  }

  private async loadAccount(accountId) {
    try {
      const account = await loadAccount(accountId)
      this.setAccount(account)
    } catch (e) {
      //This event will be picked up by the root config which will redirect to /accounts
      EventManager.broadcast('papertrail:account_access_denied', undefined)
      if (e.httpStatus !== 401) {
        Sentry.captureException(e)
      }
    }
  }

  private async loadMember(accountId) {
    try {
      const member = await loadMember(accountId)
      this.setMember(member)
    } catch (e) {
      if (e.httpStatus !== 401) {
        Sentry.captureException(e)
      }
    }
  }
}

//This exports a single instance of the class, meaning every MFE has the same one.
export const PapertrailSession = new Session()
